import CheckMark from "./Checkmark";
import Email from "./Email";
import Phone from "./Phone";
import Pin from "./Pin";
import WhatsApp from "./WhatsApp";
import { useMediaQuery } from "react-responsive";

// COLORS
const orange = "#fa823f";

// IMAGES
const imgHeader = require("./assets/header.png");
const imgFooter = require("./assets/footer.png");
const imgLogo = require("./assets/logo.png");
const imgLogo2 = require("./assets/logo2.png");
const imgMiddle = require("./assets/middle.png");
const imgLast = require("./assets/last.png");

// COMPONENTS

function App() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  if (isTabletOrMobile)
    return (
      <div>
        <header
          style={{
            textAlign: "center",
            backgroundImage: `url(${imgHeader})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            // width: "100%",
            paddingBottom: 120,
          }}
        >
          <img
            src={imgLogo}
            alt="Boxcar 77 Logo"
            style={{
              width: "auto",
              height: "120px",
              marginBottom: 42,
            }}
          />

          <div>
            <span
              style={{ fontWeight: "700", fontSize: "2rem", color: "white" }}
            >
              A
            </span>
            <span
              style={{ fontWeight: "700", fontSize: "2rem", color: orange }}
            >
              {" "}
              RENOVAÇÃO
            </span>
          </div>
          <div>
            <span
              style={{ fontWeight: "700", fontSize: "2rem", color: "white" }}
            >
              {" "}
              QUE O SEU
            </span>
          </div>
          <div>
            <span
              style={{ fontWeight: "700", fontSize: "2rem", color: "white" }}
            >
              CARRO{" "}
            </span>
            <span
              style={{
                fontWeight: "800",
                fontSize: "2rem",
                color: "white",
                fontStyle: "italic",
                textDecoration: `underline 6px ${orange}`,
              }}
            >
              MERECE
            </span>
          </div>
        </header>

        <div
          style={{
            backgroundColor: "black",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 42,
            paddingTop: 24,
            paddingBottom: 24,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 12
            }}
          >
            <span
              style={{
                color: "white",
                fontWeight: "800",
                fontSize: "2rem",
                marginBottom: 12,
              }}
            >
              Sobre nós
            </span>
            <span
              style={{
                color: "white",
                textAlign: "center",
                fontSize: 18,
                marginBottom: 4,
              }}
            >
              Onde a paixão pela perfeição automotiva encontra com o compromisso
              e a excelência. Somos profissionais altamente qualificados,
              apaixonados pelo o que fazemos, e treinados para tratar cada carro
              como se fossem nossos.
            </span>

            <button
              onClick={() => window.open("https://wa.me/5511978981212")}
              style={{
                backgroundColor: orange,
                color: "white",
                padding: "20px 30px",
                fontSize: 24,
                borderRadius: "32px",
                fontWeight: "700",
                border: "none",
                cursor: "pointer",
                marginTop: 48,
              }}
            >
              Agende seu Serviço
            </button>
          </div>
          <img
            src={imgMiddle}
            alt="Boxcar Logo"
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </div>

        <div
          style={{
            // height: 480,
            backgroundColor: orange,
            padding: 42,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: 1280,
              margin: "auto",
            }}
          >
            <span
              style={{
                color: "white",
                fontWeight: "800",
                fontSize: "2rem",
                alignSelf: "center",
              }}
            >
              Nossos Serviços
            </span>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 42,
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CheckMark />
                <span
                  style={{
                    color: "white",
                    fontWeight: "800",
                    marginBottom: 8,
                    marginTop: -28,
                    fontSize: 26,
                  }}
                >
                  LAVAGEM
                </span>
                <span style={{ color: "white", fontSize: 20 }}>
                  Limpeza profunda e
                </span>
                <span style={{ color: "white", fontSize: 20 }}>
                  minuciosa para deixar seu
                </span>
                <span style={{ color: "white", fontSize: 20 }}>
                  carro impecável.
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CheckMark />
                <span
                  style={{
                    color: "white",
                    fontWeight: "800",
                    marginBottom: 8,
                    marginTop: -28,
                    fontSize: 26,
                  }}
                >
                  POLIMENTO
                </span>
                <span style={{ color: "white", fontSize: 20 }}>
                  Brilho e proteção que
                </span>
                <span style={{ color: "white", fontSize: 20 }}>
                  fazem seu carro parecer
                </span>
                <span style={{ color: "white", fontSize: 20 }}>novo.</span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CheckMark />
                <span
                  style={{
                    color: "white",
                    fontWeight: "800",
                    marginBottom: 8,
                    marginTop: -28,
                    fontSize: 26,
                  }}
                >
                  HIGIENIZAÇÃO
                </span>
                <span style={{ color: "white", fontSize: 20 }}>
                  Ambiente interno limpo e
                </span>
                <span style={{ color: "white", fontSize: 20 }}>
                  saudável para você e sua
                </span>
                <span style={{ color: "white", fontSize: 20 }}>família.​</span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CheckMark />
                <span
                  style={{
                    color: "white",
                    fontWeight: "800",
                    marginBottom: 8,
                    marginTop: -28,
                    fontSize: 26,
                  }}
                >
                  ESTACIONAMENTO
                </span>
                <span style={{ color: "white", fontSize: 20 }}>
                  Comodidade e segurança
                </span>
                <span style={{ color: "white", fontSize: 20 }}>
                  para seu veículo.
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingBottom: 32,
          }}
        >
          <img
            src={imgLast}
            alt="Boxcar Logo"
            style={{
              width: "100%",
              height: "auto",
            }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ padding: 36, marginTop: 36, paddingBottom: 36 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 6,
                  // marginBottom: -12,
                }}
              >
                <span style={{ fontSize: 24, fontWeight: "800" }}>
                  07 MOTIVOS
                </span>
                <span
                  style={{ fontSize: 24, fontWeight: "800", color: orange }}
                >
                  PARA
                </span>
              </div>
              <span style={{ fontSize: 24, fontWeight: "800", color: orange }}>
                RENOVAR SEU CARRO
              </span>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 12,
                paddingLeft: 36,
                textAlign: "left",
                marginBottom: 12,
              }}
            >
              <span style={{ width: 12, fontSize: 20 }}>1.</span>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 6 }}
                >
                  <span style={{ fontWeight: "600", fontSize: 20 }}>
                    Valorização do Veículo:
                  </span>
                  <span style={{ fontSize: 20 }}>
                    Aumenta o valor da revenda.
                  </span>
                </div>
                {/* <span style={{ fontSize: 20 }}>revenda.</span> */}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 12,
                paddingLeft: 36,
                textAlign: "left",
                marginBottom: 12,
              }}
            >
              <span style={{ width: 12, fontSize: 20 }}>2.</span>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 6 }}
                >
                  <span style={{ fontWeight: "600", fontSize: 20 }}>
                    Preservação da Pintura:
                  </span>
                  <span style={{ fontSize: 20 }}>
                    Protege contra danos externos.
                  </span>
                </div>
                {/* <span style={{ fontSize: 20 }}>externos.</span> */}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 12,
                paddingLeft: 36,
                textAlign: "left",
                marginBottom: 12,
              }}
            >
              <span style={{ width: 12, fontSize: 20 }}>3.</span>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 6 }}
                >
                  <span style={{ fontWeight: "600", fontSize: 20 }}>
                    Conforto e Bem-Estar:
                  </span>
                  <span style={{ fontSize: 20 }}>
                    Ambiente limpo e saudável.
                  </span>
                </div>
                {/* <span style={{ fontSize: 20 }}>saudável.</span> */}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 12,
                paddingLeft: 36,
                textAlign: "left",
                marginBottom: 12,
              }}
            >
              <span style={{ width: 12, fontSize: 20 }}>4.</span>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 6 }}
                >
                  <span style={{ fontWeight: "600", fontSize: 20 }}>
                    Melhor Desempenho:
                  </span>
                  <span style={{ fontSize: 20 }}>
                    Reduz desgaste e melhora.
                  </span>
                </div>
                {/* <span style={{ fontSize: 20 }}>melhora.</span> */}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 12,
                paddingLeft: 36,
                textAlign: "left",
                marginBottom: 12,
              }}
            >
              <span style={{ width: 12, fontSize: 20 }}>5.</span>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 6 }}
                >
                  <span style={{ fontWeight: "600", fontSize: 20 }}>
                    Imagem Pessoal:
                  </span>
                  <span style={{ fontSize: 20 }}>
                    Reflete cuidado e profissionalismo.
                  </span>
                </div>
                {/* <span style={{ fontSize: 20 }}>profissionalismo.</span> */}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 12,
                paddingLeft: 36,
                textAlign: "left",
                marginBottom: 12,
              }}
            >
              <span style={{ width: 12, fontSize: 20 }}>6.</span>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 6 }}
                >
                  <span style={{ fontWeight: "600", fontSize: 20 }}>
                    Prevenção de Danos:
                  </span>
                  <span style={{ fontSize: 20 }}>
                    Identifica problemas precocemente.
                  </span>
                </div>
                {/* <span style={{ fontSize: 20 }}>precocemente.</span> */}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 12,
                paddingLeft: 36,
                textAlign: "left",
                marginBottom: 12,
              }}
            >
              <span style={{ width: 12, fontSize: 20 }}>7.</span>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 6 }}
                >
                  <span style={{ fontWeight: "600", fontSize: 20 }}>
                    Satisfação Pessoal:
                  </span>
                  <span style={{ fontSize: 20 }}>
                    Orgulho de carro impecável.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            textAlign: "center",
            backgroundImage: `url(${imgFooter})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            // width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: 120,
          }}
        >
          <span style={{ color: "white", fontSize: 24 }}>
            Para renovação ou detalhamento automotivo, conte com a
            {/* </span> */}
            {/* <span style={{ color: "white", fontSize: 24 }}> */}
            confiança e a excelência da BoxCar77. Entre em contato e
            {/* </span> */}
            {/* <span style={{ color: "white", fontSize: 24 }}> */}
            agende seu serviço hoje mesmo!
          </span>

          <button
            onClick={() => window.open("https://wa.me/5511978981212")}
            style={{
              backgroundColor: orange,
              color: "white",
              padding: "20px 30px",
              fontSize: 24,
              borderRadius: "32px",
              fontWeight: "700",
              border: "none",
              cursor: "pointer",
              marginTop: 48,
              width: 320,
              alignSelf: "center",
            }}
          >
            Agende seu Serviço
          </button>
        </div>

        <div
          style={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "row",
            padding: 72,
            // height: 120,
          }}
        >
          <div
            style={{
              maxWidth: 1280,
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: 32,
            }}
          >
            <img
              src={imgLogo2}
              alt="Boxcar 77 Logo"
              style={{
                width: "auto",
                height: 120,
              }}
            />

            <div style={{ flexDirection: "column", display: "flex" }}>
              <span
                style={{ fontWeight: "800", fontSize: 20, marginBottom: 12 }}
              >
                ENDEREÇO
              </span>
              <div style={{ flexDirection: "row", display: "flex", gap: 8 }}>
                <Pin />
                <div style={{ flexDirection: "column", display: "flex" }}>
                  <span style={{ fontSize: 18 }}>
                    Av. Morumbi, 8290 - Brooklin,
                  </span>
                  <span style={{ fontSize: 18 }}>04703-003 - São Paulo/SP</span>
                </div>
              </div>
            </div>

            <div style={{ flexDirection: "column", display: "flex" }}>
              <span
                style={{ fontWeight: "800", fontSize: 20, marginBottom: 12 }}
              >
                CONTATO
              </span>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  gap: 8,
                  alignItems: "center",
                }}
              >
                <WhatsApp />
                <span style={{ fontSize: 18 }}>11 97898-1212</span>
              </div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  gap: 8,
                  alignItems: "center",
                }}
              >
                <Phone />
                <span style={{ fontSize: 18 }}>11 5044-0071</span>
              </div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  gap: 8,
                  alignItems: "center",
                }}
              >
                <Email />
                <span style={{ fontSize: 18 }}>boxcar77estetica@gmail.com</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div>
      <header
        style={{
          textAlign: "center",
          backgroundImage: `url(${imgHeader})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          paddingBottom: 120,
        }}
      >
        <img
          src={imgLogo}
          alt="Boxcar 77 Logo"
          style={{
            width: "auto",
            height: "200px",
            marginBottom: 84,
          }}
        />

        <div>
          <span style={{ fontWeight: "700", fontSize: 80, color: "white" }}>
            A
          </span>
          <span style={{ fontWeight: "700", fontSize: 80, color: orange }}>
            {" "}
            RENOVAÇÃO
          </span>
        </div>
        <div>
          <span style={{ fontWeight: "700", fontSize: 80, color: "white" }}>
            {" "}
            QUE O SEU
          </span>
        </div>
        <div>
          <span style={{ fontWeight: "700", fontSize: 80, color: "white" }}>
            CARRO{" "}
          </span>
          <span
            style={{
              fontWeight: "800",
              fontSize: 80,
              color: "white",
              fontStyle: "italic",
              textDecoration: `underline 6px ${orange}`,
            }}
          >
            MERECE
          </span>
        </div>
      </header>

      <div
        style={{
          backgroundColor: "black",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: 42,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <span
            style={{
              color: "white",
              fontWeight: "800",
              fontSize: 54,
              marginBottom: 12,
            }}
          >
            Sobre nós
          </span>
          <span
            style={{
              color: "white",
              textAlign: "left",
              fontSize: 18,
              marginBottom: 4,
            }}
          >
            Onde a paixão pela perfeição automotiva encontra com o
          </span>

          <span
            style={{
              color: "white",
              textAlign: "left",
              fontSize: 18,
              marginBottom: 4,
            }}
          >
            compromisso e a excelência. Somos profissionais
          </span>

          <span
            style={{
              color: "white",
              textAlign: "left",
              fontSize: 18,
              marginBottom: 4,
            }}
          >
            altamente qualificados, apaixonados pelo o que fazemos,
          </span>
          <span
            style={{
              color: "white",
              textAlign: "left",
              fontSize: 18,
              marginBottom: 4,
            }}
          >
            e treinados para tratar cada carro como se fossem nossos.
          </span>

          <button
            onClick={() => window.open("https://wa.me/5511978981212")}
            style={{
              backgroundColor: orange,
              color: "white",
              padding: "20px 30px",
              fontSize: 24,
              borderRadius: "32px",
              fontWeight: "700",
              border: "none",
              cursor: "pointer",
              marginTop: 48,
            }}
          >
            Agende seu Serviço
          </button>
        </div>
        <img
          src={imgMiddle}
          alt="Boxcar Logo"
          style={{
            width: "auto",
            height: 740,
          }}
        />
      </div>

      <div
        style={{
          height: 480,
          backgroundColor: orange,
          padding: 42,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: 1280,
            margin: "auto",
          }}
        >
          <span
            style={{
              color: "white",
              fontWeight: "800",
              fontSize: 54,
              alignSelf: "center",
            }}
          >
            Nossos Serviços
          </span>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 42,
              justifyContent: "space-around",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CheckMark />
              <span
                style={{
                  color: "white",
                  fontWeight: "800",
                  marginBottom: 8,
                  marginTop: -28,
                  fontSize: 26,
                }}
              >
                LAVAGEM
              </span>
              <span style={{ color: "white", fontSize: 20 }}>
                Limpeza profunda e
              </span>
              <span style={{ color: "white", fontSize: 20 }}>
                minuciosa para deixar seu
              </span>
              <span style={{ color: "white", fontSize: 20 }}>
                carro impecável.
              </span>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CheckMark />
              <span
                style={{
                  color: "white",
                  fontWeight: "800",
                  marginBottom: 8,
                  marginTop: -28,
                  fontSize: 26,
                }}
              >
                POLIMENTO
              </span>
              <span style={{ color: "white", fontSize: 20 }}>
                Brilho e proteção que
              </span>
              <span style={{ color: "white", fontSize: 20 }}>
                fazem seu carro parecer
              </span>
              <span style={{ color: "white", fontSize: 20 }}>novo.</span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CheckMark />
              <span
                style={{
                  color: "white",
                  fontWeight: "800",
                  marginBottom: 8,
                  marginTop: -28,
                  fontSize: 26,
                }}
              >
                HIGIENIZAÇÃO
              </span>
              <span style={{ color: "white", fontSize: 20 }}>
                Ambiente interno limpo e
              </span>
              <span style={{ color: "white", fontSize: 20 }}>
                saudável para você e sua
              </span>
              <span style={{ color: "white", fontSize: 20 }}>família.​</span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CheckMark />
              <span
                style={{
                  color: "white",
                  fontWeight: "800",
                  marginBottom: 8,
                  marginTop: -28,
                  fontSize: 26,
                }}
              >
                ESTACIONAMENTO
              </span>
              <span style={{ color: "white", fontSize: 20 }}>
                Comodidade e segurança
              </span>
              <span style={{ color: "white", fontSize: 20 }}>
                para seu veículo.
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <img
          src={imgLast}
          alt="Boxcar Logo"
          style={{
            width: 500,
            height: 640,
          }}
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ padding: 36, marginTop: 36, paddingBottom: 36 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 6,
                marginBottom: -12,
              }}
            >
              <span style={{ fontSize: 36, fontWeight: "800" }}>
                07 MOTIVOS
              </span>
              <span style={{ fontSize: 36, fontWeight: "800", color: orange }}>
                PARA
              </span>
            </div>
            <span style={{ fontSize: 36, fontWeight: "800", color: orange }}>
              RENOVAR SEU CARRO
            </span>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 12,
              paddingLeft: 36,
              textAlign: "left",
              marginBottom: 12,
            }}
          >
            <span style={{ width: 12, fontSize: 20 }}>1.</span>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row", gap: 6 }}>
                <span style={{ fontWeight: "600", fontSize: 20 }}>
                  Valorização do Veículo:
                </span>
                <span style={{ fontSize: 20 }}>Aumenta o valor da</span>
              </div>
              <span style={{ fontSize: 20 }}>revenda.</span>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 12,
              paddingLeft: 36,
              textAlign: "left",
              marginBottom: 12,
            }}
          >
            <span style={{ width: 12, fontSize: 20 }}>2.</span>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row", gap: 6 }}>
                <span style={{ fontWeight: "600", fontSize: 20 }}>
                  Preservação da Pintura:
                </span>
                <span style={{ fontSize: 20 }}>Protege contra danos</span>
              </div>
              <span style={{ fontSize: 20 }}>externos.</span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 12,
              paddingLeft: 36,
              textAlign: "left",
              marginBottom: 12,
            }}
          >
            <span style={{ width: 12, fontSize: 20 }}>3.</span>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row", gap: 6 }}>
                <span style={{ fontWeight: "600", fontSize: 20 }}>
                  Conforto e Bem-Estar:
                </span>
                <span style={{ fontSize: 20 }}>Ambiente limpo e</span>
              </div>
              <span style={{ fontSize: 20 }}>saudável.</span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 12,
              paddingLeft: 36,
              textAlign: "left",
              marginBottom: 12,
            }}
          >
            <span style={{ width: 12, fontSize: 20 }}>4.</span>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row", gap: 6 }}>
                <span style={{ fontWeight: "600", fontSize: 20 }}>
                  Melhor Desempenho:
                </span>
                <span style={{ fontSize: 20 }}>Reduz desgaste e</span>
              </div>
              <span style={{ fontSize: 20 }}>melhora.</span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 12,
              paddingLeft: 36,
              textAlign: "left",
              marginBottom: 12,
            }}
          >
            <span style={{ width: 12, fontSize: 20 }}>5.</span>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row", gap: 6 }}>
                <span style={{ fontWeight: "600", fontSize: 20 }}>
                  Imagem Pessoal:
                </span>
                <span style={{ fontSize: 20 }}>Reflete cuidado e</span>
              </div>
              <span style={{ fontSize: 20 }}>profissionalismo.</span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 12,
              paddingLeft: 36,
              textAlign: "left",
              marginBottom: 12,
            }}
          >
            <span style={{ width: 12, fontSize: 20 }}>6.</span>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row", gap: 6 }}>
                <span style={{ fontWeight: "600", fontSize: 20 }}>
                  Prevenção de Danos:
                </span>
                <span style={{ fontSize: 20 }}>Identifica problemas</span>
              </div>
              <span style={{ fontSize: 20 }}>precocemente.</span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 12,
              paddingLeft: 36,
              textAlign: "left",
              marginBottom: 12,
            }}
          >
            <span style={{ width: 12, fontSize: 20 }}>7.</span>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row", gap: 6 }}>
                <span style={{ fontWeight: "600", fontSize: 20 }}>
                  Satisfação Pessoal:
                </span>
                <span style={{ fontSize: 20 }}>
                  Orgulho de carro impecável.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          textAlign: "center",
          backgroundImage: `url(${imgFooter})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          // width: "100%",
          display: "flex",
          flexDirection: "column",
          padding: 120,
        }}
      >
        <span style={{ color: "white", fontSize: 24 }}>
          Para renovação ou detalhamento automotivo, conte com a
        </span>
        <span style={{ color: "white", fontSize: 24 }}>
          confiança e a excelência da BoxCar77. Entre em contato e
        </span>
        <span style={{ color: "white", fontSize: 24 }}>
          agende seu serviço hoje mesmo!
        </span>

        <button
          onClick={() => window.open("https://wa.me/5511978981212")}
          style={{
            backgroundColor: orange,
            color: "white",
            padding: "20px 30px",
            fontSize: 24,
            borderRadius: "32px",
            fontWeight: "700",
            border: "none",
            cursor: "pointer",
            marginTop: 48,
            width: 320,
            alignSelf: "center",
          }}
        >
          Agende seu Serviço
        </button>
      </div>

      <div
        style={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          padding: 72,
          height: 120,
        }}
      >
        <div
          style={{
            maxWidth: 1280,
            margin: "auto",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 180,
          }}
        >
          <img
            src={imgLogo2}
            alt="Boxcar 77 Logo"
            style={{
              width: "auto",
              height: 120,
            }}
          />

          <div style={{ flexDirection: "column", display: "flex" }}>
            <span style={{ fontWeight: "800", fontSize: 20, marginBottom: 12 }}>
              ENDEREÇO
            </span>
            <div style={{ flexDirection: "row", display: "flex", gap: 8 }}>
              <Pin />
              <div style={{ flexDirection: "column", display: "flex" }}>
                <span style={{ fontSize: 18 }}>
                  Av. Morumbi, 8290 - Brooklin,
                </span>
                <span style={{ fontSize: 18 }}>04703-003 - São Paulo/SP</span>
              </div>
            </div>
          </div>

          <div style={{ flexDirection: "column", display: "flex" }}>
            <span style={{ fontWeight: "800", fontSize: 20, marginBottom: 12 }}>
              CONTATO
            </span>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                gap: 8,
                alignItems: "center",
              }}
            >
              <WhatsApp />
              <span style={{ fontSize: 18 }}>11 97898-1212</span>
            </div>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                gap: 8,
                alignItems: "center",
              }}
            >
              <Phone />
              <span style={{ fontSize: 18 }}>11 5044-0071</span>
            </div>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                gap: 8,
                alignItems: "center",
              }}
            >
              <Email />
              <span style={{ fontSize: 18 }}>boxcar77estetica@gmail.com</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
